import React, {
  useEffect,
  createContext,
  useCallback,
  useState,
  useContext,
} from 'react';

import { useAuth } from './auth';
import api from '../services/api';

const RequestContext = createContext({});

export const RequestProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [requestsProjeto, setRequestsProjeto] = useState([]);
  const [requestsPendentes, setRequestsPendentes] = useState([]);
  const [requestsPendentesProjetos, setRequestsPendentesProjetos] = useState([]);
  const [status, setStatus] = useState(false);

  const { user } = useAuth();

  async function getRequests() {
    if (user) {
      setLoading(true);
      api.get(`requests_program`, {
        params: {
          user_id: user?.id, // user?.profile?.name == 'Pesquisador' ? user?.id : undefined,
        },
      }).then(({ data }) => {
        setRequests(data);
        setRequestsPendentes(data.filter((item) => item.status == 'PENDENTE'));
      });

      api.get(`requests`, {
        params: {
          user_id: user?.id, // user?.profile?.name == 'Pesquisador' ? user?.id : undefined,
        },
      }).then(({ data }) => {
        setRequestsProjeto(data);
        setRequestsPendentesProjetos(data.filter((item) => item.status == 'PENDENTE'));
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const changeStatus = useCallback(async () => {
    getRequests();
    // getRequestsUrgentes(undefined, 'Todos');
    setStatus(!status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <RequestContext.Provider
      value={{
        loading,
        requests,
        requestsProjeto,
        getRequests,
        requestsPendentes,
        requestsPendentesProjetos,
        status,
        changeStatus,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

export function useRequest() {
  const context = useContext(RequestContext);

  if (!context) {
    throw Error('useRequest must be used within an RequestProvider');
  }

  return context;
}
