import React, {
  useEffect,
  createContext,
  useCallback,
  useState,
  useContext,
} from 'react';

import { useAuth } from './auth';
import api from '../services/api';

const ContaContext = createContext({});

export const ContaProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [contas, setContas] = useState([]);
  const [status, setStatus] = useState(false);

  const { user } = useAuth();

  async function getContas() {
    setLoading(true);
    if (user) {
      api.get(`contas_proximas`, {
        params: {
          coordenador_id: user.id,
        },
      }).then(({ data }) => {
        setContas(data);
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    getContas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStatus = useCallback(async () => {
    getContas();
    setStatus(!status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <ContaContext.Provider
      value={{
        loading,
        contas,
        getContas,
        status,
        changeStatus,
      }}
    >
      {children}
    </ContaContext.Provider>
  );
};

export function useConta() {
  const context = useContext(ContaContext);

  if (!context) {
    throw Error('useConta must be used within an ContaProvider');
  }

  return context;
}
