import React, {
  useEffect,
  createContext,
  useCallback,
  useState,
  useContext,
} from 'react';

import { isUuid, uuid } from 'uuidv4';
import { store } from 'react-notifications-component';
import api from '../services/api';
import { useAuth } from './auth';

const ProgramContext = createContext({});

export const ProgramProvider = ({ children }) => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);

  const [status, setStatus] = useState(false);

  useEffect(() => {
    async function loadPrograms() {
      if (user) {
        api.get(`programs`).then(({ data }) => {
          setPrograms(data);
        });
      }
    }

    loadPrograms();
  }, [user]);

  const create = useCallback(async (data) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    // formData.append("file", file);

    api.post(`programs`, formData).then(({ data: program }) => {
      setPrograms([...programs, program]);

      store.addNotification({
        message: `Programa inserido com sucesso!`,
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }).finally(() => {
      setLoading(false);
    });
  }, [programs]);

  const addNotice = useCallback(async (data, file, files) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("beggin", data.beggin);
    formData.append("end", data.end);
    formData.append("beggin_prestacao_conta", data.beggin_prestacao_conta);
    formData.append("end_prestacao_conta", data.end_prestacao_conta);
    formData.append("documents", data.documents);
    formData.append("description", data.description);
    formData.append("program_id", data.id);

    if (file) {
      formData.append("file", file);
    }

    const configurations = {
      plano_trabalho: JSON.stringify({
        fields: {
          titulo_projeto: { checked: false, value: 0 },
          coordenador: { checked: false, value: 0 },
          email: { checked: false, value: 0 },
          faixa_valor: {
            checked: false,
            value: [{
              id: uuid(),
              especializacao: [],
              min: 'R$ 0,00',
              max: 'R$ 0,00',
              capital_min: 'R$ 0,00',
              capital_max: 'R$ 0,00',
              custeio_min: 'R$ 0,00',
              custeio_max: 'R$ 0,00',
              percents_min: '0.0%',
              percents_max: '0.0%',
            }],
          },
          bolsas: {
            checked: false,
            value: [
              {
                id: uuid(),
                especializacao: [],
                min: 'R$ 0,00',
                max: 'R$ 0,00',
                capital_min: 'R$ 0,00',
                capital_max: 'R$ 0,00',
                custeio_min: 'R$ 0,00',
                custeio_max: 'R$ 0,00',
                percents_min: '0.0%',
                percents_max: '0.0%',
              },
            ],
          },
          tema_interesse: { checked: false, value: 0 },
          instituicao: { checked: false, value: 0 },
          unidade_executora: { checked: false, value: 0 },
          linha_pesquisa: { checked: false, value: 0 },
          inicio_previsto: { checked: false, value: 0 },
          duracao: { checked: false, value: 0 },
          cotacao_moeda_estrangeira: { checked: false, value: 'R$ 0,00' },
        },
        documentos_pessoais: [],
      }),
      apresentacao: JSON.stringify({
        apresentacao: {
          resumo: { checked: false, value: 0 },
          palavras_chave: { checked: false, value: 0 },
          informacoes_relevantes_para_avaliacao: { checked: false, value: 0 },
          experiencia_coordenador: { checked: false, value: 0 },
          sintese_projeto: { checked: false, value: 0 },
          objetivos_gerais: { checked: false, value: 0 },
          objetivos_especificos: { checked: false, value: 0 },
          metodologia: { checked: false, value: 0 },
          resultados_esperados: { checked: false, value: 0 },
          impactos_esperados: { checked: false, value: 0 },
          riscos_atividades: { checked: false, value: 0 },
          referencia_bibliografica: { checked: false, value: 0 },
          estado_arte: { checked: false, value: 0 },
        },
      }),
      orcamento: JSON.stringify({
        orcamentos: {
          bolsas: {
            checked: false,
            value: [
              {
                id: uuid(),
                especializacao: [],
                quantidade: 0,
                n_bolsas: 0,
              },
            ],
          },
          diarias: { checked: false, value: 0 },
          hospedagem_alimentacao: { checked: false, value: 0 },
          materiais_consumo: { checked: false, value: 0 },
          passagens: { checked: false, value: 0 },
          servicos_terceiros: { checked: false, value: 0 },
          materiais_equipamentos: { checked: false, value: 0 },
          pessoal: { checked: false, value: 0 },
          encargos: { checked: false, value: 0 },
        },
      }),
      abrangencia: JSON.stringify({
        abrangencias: {
          abrangencia: { checked: false, value: 0 },
        },
      }),
      membros: JSON.stringify({
        equipes: {
          equipe: { checked: false, value: 0 },
        },
      }),
      atividades: null,
      recursos_proprios: null,
      recursos_solicitados_outros: JSON.stringify({
        recursos: {
          recurso: { checked: false, value: 0 },
        },
      }),
      etapas_contratacao: JSON.stringify({
        etapasContratacao: {
          enquadrado: { checked: true, value: 0 },
          adhoc: { checked: true, value: 0 },
          interno: { checked: true, value: 0 },
          contratacao: { checked: true, value: 0 },
        },
      }),
    };

    const edital = await api.post(`files`, formData);
    await api.post(`configurations`, { ...configurations, file_id: edital.data.id });

    await Promise.all(files.map(async (file) => {
      const formData = new FormData();
      formData.append("title", file.title);
      formData.append("file_id", edital.data.id);
      formData.append("file", file.file);

      await api.post('/editais/attachments', formData);
    }));

    store.addNotification({
      message: `Chamada Pública inserida com sucesso!`,
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });

    setStatus(!status);

    setLoading(false);
  }, [status]);

  const removeNotice = useCallback(async (data) => {
    setLoading(true);

    api.delete(`files/${data.id}`).then(() => {
      store.addNotification({
        message: `Chamada Pública removida com sucesso!`,
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const updateNotice = useCallback(async (data, file) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("beggin", data.beggin);
    formData.append("end", data.end);
    formData.append("beggin_prestacao_conta", data.beggin_prestacao_conta);
    formData.append("end_prestacao_conta", data.end_prestacao_conta);
    formData.append("documents", data.documents);
    formData.append("description", data.description);

    if (file) {
      formData.append("file", file);
    }

    api.put(`files/update/${data.id}`, formData).then(({ data }) => {
      store.addNotification({
        message: `Chamada Pública atualizada com sucesso!`,
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      setStatus(!status);
    }).finally(() => {
      setLoading(false);
    });
  }, [status]);

  const update = useCallback(async (data, file) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);

    if (file) {
      formData.append("file", file);
    }

    api.put(`programs/${data.id}`, formData).then(({ data: program }) => {
      setPrograms(programs.map((item) => (item.id === data.id ? program : item)));

      store.addNotification({
        message: `Programa atualizado com sucesso!`,
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }).finally(() => {
      setLoading(false);
    });
  }, [programs]);

  const erase = useCallback(async (data) => {
    setLoading(true);

    api.delete(`programs/${data.id}`).then(() => {
      setPrograms(programs.filter((item) => (item.id !== data.id)));

      store.addNotification({
        message: `Programa deletado com sucesso!`,
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }).finally(() => {
      setLoading(false);
    });
  }, [programs]);

  const changeStatus = useCallback(async () => {
    setStatus(!status);
  }, [status]);

  return (
    <ProgramContext.Provider
      value={{
        programs,
        loading,
        create,
        addNotice,
        removeNotice,
        updateNotice,
        update,
        erase,
        status,
        changeStatus,
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
};

export function useProgram() {
  const context = useContext(ProgramContext);

  if (!context) {
    throw Error('useProgram must be used within an ProgramProvider');
  }

  return context;
}
