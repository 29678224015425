import axios from 'axios';

export const url = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: url,
});

/* api.interceptors.response.use(null, (error) => {
  console.clear();

  return Promise.reject(error);
}); */

export default api;
