import React from "react";
import ReactDOM from "react-dom";
import ReactNotification from "react-notifications-component";
import Routes from "./routes";
import "react-notifications-component/dist/theme.css";
import "moment/locale/pt-br";
import "react-perfect-scrollbar/dist/css/styles.css";

import api from './services/api';

import AppProvider from "./hooks";

const App = () => {
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  React.useEffect(() => {
    clearCacheData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const production = process.env.REACT_APP_PRODUCTION || 'false';
    if (production == 'true') {
      const noop = () => {};
      [
        "assert",
        // "clear",
        "count",
        "debug",
        "dir",
        "dirxml",
        "error",
        "exception",
        "group",
        "groupCollapsed",
        "groupEnd",
        "info",
        "log",
        "markTimeline",
        "profile",
        "profileEnd",
        "table",
        "time",
        "timeEnd",
        "timeline",
        "timelineEnd",
        "timeStamp",
        "trace",
        "warn",
      ].forEach((method) => {
        window.console[method] = noop;
      });
    }
  }, []);

  return <Routes />;
};

ReactDOM.render(
  <AppProvider>
    <ReactNotification />
    <App />
  </AppProvider>,
  document.getElementById("root"),
);
