/* eslint-disable no-shadow */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
  Suspense, lazy,
} from 'react';

import { ModalProvider } from 'styled-react-modal';
import { store } from 'react-notifications-component';

import api from '../services/api';

const AuthContext = createContext({});

let ModalForm = () => <></>;
const Form = import("./Form");

export const AuthProvider = ({ children }) => {
  const [OpenForm, setOpenForm] = useState(false);

  async function toggleModalForm() {
    ModalForm = await lazy(() => Form);

    setOpenForm(!OpenForm);
  }

  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem('@sigfapeap:token');
    const refreshToken = localStorage.getItem('@sigfapeap:refreshToken');
    const user = localStorage.getItem('@sigfapeap:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      api.defaults.headers.refreshToken = refreshToken;
      api.defaults.headers.userid = JSON.parse(user).id;
      const parseUser = JSON.parse(user);

      api.interceptors.response.use(null, async (error) => {
        /* const status = error?.response?.status;
        if (status && Number(status) === 401) {
          setAuth({});
          localStorage.removeItem('@sigfapeap:token');
          localStorage.removeItem('@sigfapeap:refreshToken');
          localStorage.removeItem('@sigfapeap:user');
        } */

        const errorData = error.response?.data?.message;
        if (errorData && errorData === "E_JWT_TOKEN_EXPIRED: The jwt token has been expired. Generate a new one to continue") {
          //   alert("Sessão expirada, para continuar utilizando o sistema você deve se autenticar novamente");
        //   toggleModalForm();
          ModalForm = await lazy(() => Form);
          setOpenForm(true);
          // setAuth({});
          // localStorage.removeItem('@sigfapeap:token');
          // localStorage.removeItem('@sigfapeap:refreshToken');
          // localStorage.removeItem('@sigfapeap:user');

          return Promise.reject(error);
        }

        return Promise.reject(error);
      });

      return {
        token,
        user: parseUser,
      };
    }

    return {};
  });

  const signed = useMemo(
    () => !!auth.token && !!auth.user,
    [auth],
  );

  const signOut = useCallback(() => {
    setOpenForm(false);
    setAuth({});
    localStorage.removeItem('@sigfapeap:token');
    localStorage.removeItem('@sigfapeap:refreshToken');
    localStorage.removeItem('@sigfapeap:user');
  }, []);

  const refreshUser = useCallback(async (data) => {
    /* setLoading(true);
    const response = await api.get('/profile');

    const user = response.data;

    localStorage.setItem('@sigfapeap:user', JSON.stringify(user));

    setAuth((oldData) => ({ ...oldData, user }));
    setLoading(false); */

    localStorage.setItem('@sigfapeap:token', data.auth.token);
    localStorage.setItem('@sigfapeap:refreshToken', data.auth.refreshToken);

    api.defaults.headers.authorization = `Bearer ${data.auth.token}`;
    api.defaults.headers.refreshToken = data.auth.refreshToken;

    setAuth((oldData) => ({ ...oldData, token: data.auth.token }));
  }, []);

  const refresh = useCallback(async () => {
    const { data } = await api.get(`auth/refresh`);
    localStorage.setItem('@sigfapeap:user', JSON.stringify(data));
    setAuth((oldData) => ({ ...oldData, user: data }));
  }, []);

  const signIn = useCallback(async ({ cpf, password }) => {
    setLoading(true);

    try {
      const { data } = await api.post(`auth/login`, {
        cpf,
        password,
      });

      api.defaults.headers.authorization = `Bearer ${data.auth.token}`;
      api.defaults.headers.refreshToken = data.auth.refreshToken;
      api.defaults.headers.userid = data.user.id;

      api.interceptors.response.use(null, async (error) => {
        /* const status = error?.response?.status;
        if (status && Number(status) === 401) {
          setAuth({});
          localStorage.removeItem('@sigfapeap:token');
          localStorage.removeItem('@sigfapeap:refreshToken');
          localStorage.removeItem('@sigfapeap:user');
        } */

        const errorData = error.response?.data?.message;
        if (errorData && errorData === "E_JWT_TOKEN_EXPIRED: The jwt token has been expired. Generate a new one to continue") {
          //   alert("Sessão expirada, para continuar utilizando o sistema você deve se autenticar novamente");
        //   toggleModalForm();
          ModalForm = await lazy(() => Form);
          setOpenForm(true);
          // setAuth({});
          // localStorage.removeItem('@sigfapeap:token');
          // localStorage.removeItem('@sigfapeap:refreshToken');
          // localStorage.removeItem('@sigfapeap:user');

          return Promise.reject(error);
        }

        return Promise.reject(error);
      });

      if (!OpenForm) {
        setAuth({ token: data.auth.token, user: data.user });
      }

      localStorage.setItem('@sigfapeap:user', JSON.stringify(data.user));
      localStorage.setItem('@sigfapeap:token', data.auth.token);
      localStorage.setItem('@sigfapeap:refreshToken', data.auth.refreshToken);

      setLoading(false);

      if (OpenForm) {
        // toggleModalForm();
        setOpenForm(false);
        // window.location.reload();
      } else {
        window.location.href = '/';
      }
    } catch (err) {
      setLoading(false);

      localStorage.removeItem('@sigfapeap:token');
      localStorage.removeItem('@sigfapeap:refreshToken');
      localStorage.removeItem('@sigfapeap:user');

      const message = 'Ocorreu um erro ao fazer login, verifique suas credenciais!';

      store.addNotification({
        message,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  }, [OpenForm]);

  return (
    <AuthContext.Provider
      value={{
        user: auth.user,
        setAuth,
        loading,
        signIn,
        signOut,
        refreshUser,
        refresh,
        token: auth.token,
        signed,
      }}
    >
      {children}

      <Suspense fallback={null}>
        <ModalProvider>
          <ModalForm isOpen={OpenForm} toggleModal={toggleModalForm} signIn={signIn} loading={loading} signOut={signOut} />
        </ModalProvider>
      </Suspense>
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
