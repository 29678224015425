import React, {
  createContext,
  useState,
  useContext,
} from 'react';

const ReciboContext = createContext({});

export const ReciboProvider = ({ children }) => {
  const [recibos, setRecibos] = useState([]);

  return (
    <ReciboContext.Provider
      value={{
        recibos,
        setRecibos,
      }}
    >
      {children}
    </ReciboContext.Provider>
  );
};

export function useRecibo() {
  const context = useContext(ReciboContext);

  if (!context) {
    throw Error('useRecibo must be used within an ReciboProvider');
  }

  return context;
}
